exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-firecart-privacy-policy-js": () => import("./../../../src/pages/firecart/privacy-policy.js" /* webpackChunkName: "component---src-pages-firecart-privacy-policy-js" */),
  "component---src-pages-firecart-terms-and-conditions-js": () => import("./../../../src/pages/firecart/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-firecart-terms-and-conditions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playbasketball-privacy-js": () => import("./../../../src/pages/playbasketball/privacy.js" /* webpackChunkName: "component---src-pages-playbasketball-privacy-js" */),
  "component---src-pages-playbasketball-terms-js": () => import("./../../../src/pages/playbasketball/terms.js" /* webpackChunkName: "component---src-pages-playbasketball-terms-js" */),
  "component---src-pages-react-native-expertise-index-js": () => import("./../../../src/pages/react-native-expertise/index.js" /* webpackChunkName: "component---src-pages-react-native-expertise-index-js" */),
  "component---src-pages-vijesti-ukratko-privacy-policy-js": () => import("./../../../src/pages/vijesti-ukratko/privacy-policy.js" /* webpackChunkName: "component---src-pages-vijesti-ukratko-privacy-policy-js" */),
  "component---src-pages-vijesti-ukratko-terms-and-conditions-js": () => import("./../../../src/pages/vijesti-ukratko/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-vijesti-ukratko-terms-and-conditions-js" */)
}

